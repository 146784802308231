import { React } from "react";
import { Link } from 'react-router-dom'
import "./itemDetail.css"

export const ItemDetail = ({ dato }) => {
  const todo = [dato.detalle].flat();
  const todos = [dato.detalles].flat();
  function carros(e) {
    return (
      <>
        <td>{todo[e].modelo}</td>
        <td>{todo[e].reja}</td>
        <td>{todo[e].a}</td>
        <td>{todo[e].b}</td>
        <td>{todo[e].c}</td>
        <td>{todo[e].d}</td>
        <td>{todo[e].e}</td>
        <td>{todo[e].peso}</td>
      </>
    )
  }

  function carretas(e) {
    return (
      <>
        <td>{todos[e].a}</td>
        <td>{todos[e].b}</td>
        <td>{todos[e].c}</td>
        <td>{todos[e].peso}</td>
      </>
    )
  }


  return (
    <>
      <div className="container-fluid">
        <h4 className="boxCategory">{dato.categoria}</h4>
        <h2>{dato.producto}</h2>
        <p className="icon-checkmark info"> {dato.stock}</p>
        <div>
          <img className="detalleImg" src={dato.img}></img>
        </div>
        <div className="info">
          <p>{dato.infoA}</p>
        </div>
        <div>
          {dato.detalle !== undefined || null ? (
            <>
              <h2>Tipo de carros y Medidas</h2>
              <div className="boxTable">
                <table className="tables">
                  <tr>
                    <th>Modelo</th>
                    <th>Tipo de reja Trasera</th>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>D</th>
                    <th>E</th>
                    <th>Peso Kg.</th>
                  </tr>
                  <tr>{carros(0)}</tr>
                  <tr>{carros(1)}</tr>
                  <tr>{carros(2)}</tr>
                  <tr>{carros(3)}</tr>
                  <tr>{carros(4)}</tr>
                  <tr>{carros(5)}</tr>
                  <tr>{carros(6)}</tr>
                  <tr>{carros(7)}</tr>
                  <tr>{carros(8)}</tr>
                </table>
                {dato.img2 !== (null || undefined) ? (<div><img className="boxImg" src={dato.img2}></img></div>) : (<></>)}
              </div>
            </>) : (<></>)
          }
          {dato.detalles !== undefined || null ? (
            <>
              <h2>Medidas</h2>
              <div className="boxTable">
                <table className="tables">
                  <tr>
                    <th>A</th>
                    <th>B</th>
                    <th>C</th>
                    <th>Peso Kg.</th>
                  </tr>
                  <tr>{carretas(0)}</tr>
                </table>
                {dato.img2 !== (null || undefined) ? (<div><img className="boxImg" src={dato.img2}></img></div>) : (<></>)}
              </div>
            </>) : (<></>)
          }
          {(dato.detalles || dato.detalle) == undefined || null ? (
            <>
              <h2>MEDIDAS</h2>
              <div className="cajaDetalle">
                <p>LARGO: {dato.largo}</p>
                <p>ALTO: {dato.alto}</p>
                <p>ANCHO: {dato.ancho}</p>
                <p>PESO: {dato.peso}</p>
                <p>CAPACIDAD DE CARGA: {dato.carga}</p>
                <p>BASE: {dato.base}</p>
                <p>RUEDA: {dato.rueda}</p>
                <p>TERMINACIÓN: {dato.terminacion}</p>
              </div>
            </>) : (<></>)
          }
        </div>
        <div >
          <h2>Formas de Pagos</h2>
          <div className="boxPago">
            <img src="../../img/logo__large.png" alt=" mercado  pago" />
            <p className='icon-library'><span>Trasferencias Bancarias</span></p>
          </div>
          <button className="icon-circle-up buttonInicio">
            <Link className="buttonInicio" to="/">Inicio</Link></button>
        </div>
      </div>
    </>
  );
};
