import React from 'react'
import { useForm } from "react-hook-form";
import "./contactos.css"

export default function Contactos() {
    const { register, formState: { errors }, handleSubmit } = useForm()
    const onSubmit = (data) => {
        console.log(data);
    }
    return (
        <>
            <h1 className='icon-profile'>¿ Preguntas ?</h1>
            <div className='caja'>
                <div className='boxFrom' >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <div>Nombre y Apellido</div>
                            <input type="text" {...register("nombre", { required: true })} />
                            {errors.nombre?.type === "required" && <p>El campo esta vacio</p>}
                        </div>
                        <div>
                            <div>Mail</div>
                            <input type="mail" {...register("mail", { required: true }, { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i })} />
                            {errors.mail?.type === "pattern" && <p>Mail invalido</p>}
                        </div>
                        <div>
                            <div>Asunto</div>
                            <select {...register("asunto")}>
                                <option value="Reparación">Reparación</option>
                                <option value="Producto Nuevo">Producto Nuevo</option>
                            </select>
                        </div>
                        <div>
                            <div>Mensaje</div>
                            <textarea type="text" {...register("mensaje")} />
                        </div>
                        <input type="submit" value="Enviar" />
                    </form>
                </div>
                <div className='contenidoContacto separacion'>
                    <p className='separacion'>Contactos</p>
                    <p className='icon-mobile separacion'>  +54(011) 1557962254 <i className='icon-whatsapp'></i></p>
                    <p className='icon-mail4 separacion'>  marcar_41@hotmail.com.ar</p>
                </div>
            </div>
        </>
    )
}
