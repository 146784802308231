import { useEffect, useState, useContext } from "react";
import "./navbar.css"
import { Link } from 'react-router-dom'
import { CartContext } from './../../context/stacticContext';

export const Navbar = () => {
  const [categorias, setCategorias] = useState([])
  const context = useContext(CartContext)
  useEffect(() => {
    async function getCategory() {
      fetch('../../../json/categorias.json')
        .then(response => response.json())
        .then((response) => { setCategorias(response) })
    }
    getCategory()
  }, [])

  return (<>
    <div className={`navBox ${context.menu ? 'isActive' : ' '}`}>
      <button onClick={context.toggleMenu} className="icon-menu despligaMenu"></button>
      <nav className={`navbarDetails ${context.menu ? 'isActive' : ' '}`}>
        <ul className='navbarFiltro'>
          <button onClick={context.toggleMenu} className="navButton">
            <Link className="menu" to="/">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
              Todo</Link>
          </button>
          {!!categorias.lenght
            ? "cargando" : categorias.map((categoria, index) => {
              return (
                <button onClick={context.toggleMenu} className="navButton menu" key={index}>
                  <Link className="menu" to={`/categoria/${categoria}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    {categoria}</Link>
                </button>)
            })
          }
        </ul>
      </nav >

    </div>
  </>

  );
};
