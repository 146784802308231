import React from "react";
import "./footer.css";

export const Footer = () => {
  return (
    <>
      <footer className="acomodo">
          <div className="mi-contacto">
          <p>&copy; copyright 2023&reg;Lucas Bordon</p>
          </div>
      </footer>
    </>
  );
};
