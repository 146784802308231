import { React} from 'react'
import CustomProvider from './context/stacticContext';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Navbar } from './contenedores/navbar/navbar.jsx';
import { ProductContainer } from './contenedores/productContainer/productContainer';
import { ItemDetailProducto } from './contenedores/itemDetail/itemDetailProducto';
import { Footer } from './contenedores/footer/footer';
import Contactos from './componentes/contactos/Contactos';
import Encabezado from './contenedores/detalles/encabezado';
import './fonts/iconmoon.css';
import './App.css';

function App() {
  return (
    <div className="App col-12 light" >
      <CustomProvider>
        <BrowserRouter>
          <Encabezado />
          <Navbar />
          <Routes>
            <Route path="/" element={<ProductContainer />} />
            <Route path="/categoria/:categoria" element={<ProductContainer />} />
            <Route path="/productos/:productId" element={<ItemDetailProducto />} />
            <Route path="*" component={() => <h1>ERROR 404 !!! pagina no encontrada...</h1>} />
          </Routes>
          <Contactos />
          <Footer />
        </BrowserRouter>
      </CustomProvider>
    </div>
  );
}

export default App;
