import { Link } from "react-router-dom";
import "./product.css";

export const Product = ({ dato }) => {

  return (
    <>
      <div className="product">
        <img className="productImg" src={dato.img}></img>
        <p className="productName">{dato.producto}</p>
        <div className="productDetails">
          <Link to={`/Productos/${dato.id}`} className="verDetalle">Ver Detalle</Link>
        </div>
          <p className="boxInfo">{dato.disponibilidad}</p>
      </div>
    </>
  );
};
