import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ItemDetail } from "./../../componentes/itemDetail/itemDetail";

export const ItemDetailProducto = () => {
  let { productId } = useParams();
  const id = Number(productId)
  const [producto, setProducto] = useState([]);
  const [description, setDescription] = useState([{}]);

  useEffect(() => {
    async function getData() {
      fetch("../../../json/productos.json")
      .then((response) => response.json())
      .then((data) => {
        setProducto(data);
      });
    }
    getData();
  }, []);
  
  setTimeout(() => {
    if (producto.id === productId) {
    } 
    verte()
  }, 5)
  
 function verte() {
    let seleccionado = producto.filter((product) => product.id === id);
    setDescription(seleccionado);
  }
  
  return (
    <>
      <article className="row col-12 productContainer">
        {description.map((data, index) => {
          return (
            <div>
              <ItemDetail dato={data} key={index} id={id} />
            </div>
          );
        })}
      </article>
    </>
  );
};
