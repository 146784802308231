import React from 'react'
import './encabezado.css'

export default function Encabezado() {
  return (
    <>
      <h1 className='icon-cart'> Carros Marcar</h1>
      <div className="listaContacto">
        <p className='icon-mobile textContacto'>  +54(011) 1557962254 <i className='icon-whatsapp'></i></p>
        <p className='icon-mail4 textContacto'>  marcar_41@hotmail.com.ar</p>
      </div>
      <div className='caja'>
        <div className='contenido'>
          <h4>Reparación y ventas de nuevos productos</h4>
          <h5>El Servicio de reparación comprende:</h5>
          <ul className='corte'>
            <p className='icon-checkmark text'>  Desarmado total y la reposición de alambres faltantes soldadura general.</p>
            <p className='icon-checkmark text'>  Cambios de ruedas y demás piezas en mal estado.</p>
            <p className='icon-checkmark text'>  Cambios de manijas con logo publicitario.</p>
          </ul>
        </div>
        <div className='contenido'>
          <h3>Plazo de entregas <span className='icon-truck'></span></h3>
          <p className='text'>
            El plazo de entrega es acordado al momento de tener la cantidad exacta del producto nuevo o  por contrato de servicio de reparación (En ambos casos se tiene que evaluar la cantidad del pedido total).
          </p>
        </div>
      </div>
      <a className="btn-wsp" href='https://api.whatsapp.com/send?phone=5491157962254&text=Hola%20%20Carros%20Marcar%20,%20Quisiera%20info' target='_blank'><i className='icon-whatsapp'></i></a>
    </>
  )
}
