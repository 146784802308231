import { React, useContext, useEffect, useState } from "react";
import { Product } from "../../componentes/product/product";
import { useParams } from "react-router-dom";
import { CartContext } from "../../context/stacticContext";
import "./productContainer.css";

export const ProductContainer = () => {
  const [producto, setProducto] = useState([]);
  const { categoria } = useParams();
  const context = useContext(CartContext);
  useEffect(() => {
    async function getData() {
      fetch("../../../json/productos.json")
        .then((response) => response.json())
        .then((data) => {
          setProducto(data);
        });
    }
    if (categoria === undefined) {
      getData();
    } else {
      filtroPorCategoria();
    }
  }, [categoria]);

  function filtroPorCategoria() {
    const seleccionado = context.producto.filter(
      (product) => product.categoria === categoria
    );
    setProducto(seleccionado);
    console.log(seleccionado);
  }

  return (
    <>
      {categoria !== (null || undefined) ? (<h1 className="boxCategory">{categoria}</h1>) : (<h1 className="boxCategory">Todos</h1>)}
      <div className="productContainer">
        {producto.map((data, Index) => {
          return (
            <div className="productoSeparacion">
              <Product dato={data} key={Index} />
            </div>
          );
        })}
      </div>
    </>
  );
};
