import React, { createContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const CartContext = createContext()
const { Provider } = CartContext

const CustomProvider = ({ children }) => {
  const [producto, setProducto] = useState([])
  const [categorias, setCategorias] = useState([])
  const [menu, setMenu] = useState(false)
  const { categoria } = useParams()

  useEffect(() => {
    async function getData() {
      fetch('../../../json/productos.json')
        .then(response => response.json())
        .then(data => { setProducto(data) })
    }
    getData()
  }, [categoria])

  useEffect(() => {
    async function getCategory() {
      fetch('../../../json/categorias.json')
        .then(response => response.json())
        .then((response) => {
          setCategorias(response)
        })
    }
    getCategory()
  }, [])


  const toggleMenu = () => {
    setMenu(!menu)
    console.log(menu + "menu");
  }

  /*dark mode*/
  const [tema, setTema] = useState("dark")

  const toggleTheme = () => {
    setTema((curr) => curr === "light" ? "dark" : "light")
  }

  /*fin dark mode*/


  return (
    <Provider value={{ producto, categoria, categorias, tema, toggleTheme, toggleMenu, menu }}>
      {children}
    </Provider>
  )
}


export default CustomProvider;